<div class="notification">
  <div *ngIf="notification.status === 'UNREAD'" class="alert"></div>

  <div class="notification-content">
    <div [ngClass]="notification.status === 'UNREAD' ? 'paragraph-m-strong' : 'paragraph-m'" [innerHTML]="header"></div>

    <div [ngClass]="notification.status === 'UNREAD' ? 'paragraph-m-strong' : 'paragraph-m'"  *ngIf="subHeader" [innerHTML]="subHeader"></div>

    <div class="notification-content-details">
      <div class="detail-box" *ngIf="bodyContent">
        <div
          class="notification-message"
          [innerHTML]="bodyContent"
          (click)="handleNotificationClick($event)"
        ></div>
      </div>
    </div>

    <div class="paragraph-m" *ngIf="footerContent" [innerHTML]="footerContent"></div>

    <div class="close-container">
      <ps-icon
        (click)="handleDeleteClick()"
        (keydown.enter)="handleDeleteClick()"
        role="button"
        tabindex="0"
        aria-hidden="false"
        aria-label="delete"
        color="black"
        size="medium"
      >
        <svg width="24" height="24" viewBox="0 0 24 24" fill="black" xmlns="http://www.w3.org/2000/svg">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M17.71 7.09004L16.91 6.29004L12 11.3L7.09004 6.29004L6.29004 7.09004L11.3 12L6.29004 16.91L7.09004 17.71L12 12.7L16.91 17.71L17.71 16.91L12.7 12L17.71 7.09004Z"
            fill="#262626"
          />
        </svg>
      </ps-icon>
    </div>
  </div>
</div>
