import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {catchError, concatMap, map, Observable, of} from 'rxjs';
import {MetricService} from './services/metric/metric.service';
import {ClientService} from './services/client/client.service';
import {CVSBannerService, CVSBannerType} from 'angular-component-library';
import {MetricContext} from '@pbm-shared/pbm-shared-models';

@Injectable({
  providedIn: 'root'
})
export class MetricContextResolver implements Resolve<MetricContext> {

  constructor(
    private metricService: MetricService,
    private clientService: ClientService,
    private bannerService: CVSBannerService
  ) { }

  private getClientAndMetricsConfig() {
    return this.clientService.selectedClient$.pipe(
      concatMap(client => {
        return this.metricService.getMetricsConfig(client.clientCode);
      }))
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<MetricContext> {
    return this.getClientAndMetricsConfig().pipe(
      map((metricConfig) => {
        return {metricConfig: metricConfig};
      }),
      catchError(x=> {
        setTimeout(()=> {
          this.bannerService.sendAlert({bannerType: CVSBannerType.Error})
        })
        return of({metricConfig: []});
      })
    )
  }
}
