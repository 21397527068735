
export enum PageTitleRoutesEnum {
  Dashboard = "dashboard",
  Reporting = "reporting",
  Clientcontacts = "clientcontacts",
  Cvscontacts = "cvscontacts",
  Consultantcontacts = "consultantcontacts",
  Vendorcontacts = "vendorcontacts",
  Clinical = "clinical",
  Formularysearch = "formularysearch",
  Plandashboard = "plandashboard",
  Eligibility = "eligibility",
  Clinicalprogram = "clinicalprogram",
  Accessibility = "accessibility",
  PrivacyPolicy = "privacy_policy",
  TermsOfUse = "terms_of_use",
  ClientProfile='client_profile',
  Contacts='contacts'
}

const PageTitles = {
  [PageTitleRoutesEnum.Dashboard]: 'Dashboard',
  [PageTitleRoutesEnum.Reporting]: 'Reporting',
  [PageTitleRoutesEnum.Clientcontacts]: 'Client Contacts',
  [PageTitleRoutesEnum.Cvscontacts]: 'CVS/Caremark Contacts',
  [PageTitleRoutesEnum.Consultantcontacts]: 'Consultant Contacts',
  [PageTitleRoutesEnum.Vendorcontacts]: 'Vendor Contacts',
  [PageTitleRoutesEnum.Clinical]: 'Clinical Plan Management',
  [PageTitleRoutesEnum.Formularysearch]: 'Formulary & Drug Search',
  [PageTitleRoutesEnum.Plandashboard]: 'Benefit Plans Dashboard',
  [PageTitleRoutesEnum.Eligibility]: 'Member Eligibility',
  [PageTitleRoutesEnum.Clinicalprogram]: '',
  [PageTitleRoutesEnum.Accessibility]: 'Accessibility',
  [PageTitleRoutesEnum.PrivacyPolicy]: 'Privacy Policy',
  [PageTitleRoutesEnum.TermsOfUse]: 'Terms of Use',
  [PageTitleRoutesEnum.ClientProfile]: 'Client Profile',
  [PageTitleRoutesEnum.Contacts]: 'Contacts',
}

export const getPageTitle = (key: PageTitleRoutesEnum) => {
  if (key) {
    return `${PageTitles[key]} | myPBM™: Simplified Management`
  }
  return 'myPBM™: Simplified Management';
}
