import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {BehaviorSubject, debounceTime, forkJoin, from, last, lastValueFrom, map, Observable, of, retry, shareReplay, switchMap, take, takeLast, tap} from 'rxjs';
import { ClientModel } from '@pbm-shared/pbm-shared-models';
import { environment } from '../../../environments/environment';
import {AuthorizationService} from 'angular-component-library';
import {ClientInfoModel} from '../../shared/clientinfo.model';

@Injectable({
  providedIn: 'root'
})
export class ClientService {
  private _selectedClient: BehaviorSubject<ClientModel> = new BehaviorSubject<ClientModel>(null);
  selectedClient$ = this._selectedClient.asObservable();
  private _allClient: BehaviorSubject<{data: ClientModel[]}> = new BehaviorSubject<{data: ClientModel[]}>(null);
  allClient$ = this._allClient.asObservable();
  private _hasMultiClient : BehaviorSubject<Boolean> = new BehaviorSubject<Boolean>(true);
  multiClient$ = this._hasMultiClient.asObservable();
  clientInfoesCallCache: Observable<{data: any[]}>;

  constructor(
    private httpClient: HttpClient,
    private authorizationService: AuthorizationService,
  ) { }

  getUserClients(): Observable<{data: ClientModel[]}> {
    if(!this._allClient.value) {
      return this.authorizationService.getAuthorizedUser().pipe(
       switchMap(user => {
          const clientUniqueIds = new Set<string>();
          (user?.assignedPortalClients || []).forEach((client: ClientModel) => {
            if ((client.accountLevel === 'ClientCode' || client.accountLevel === undefined) && client.clientUniqueId){
              clientUniqueIds.add(client.clientUniqueId);
            }
          })
          return this.filterE2EClients(clientUniqueIds)
        }),
        tap(e2eClients => { this._allClient.next(e2eClients) }));
    }
    return this.allClient$;
  }
  
  filterE2EClients(clientUniqueIds: Set<string>): Observable<{data: ClientModel[]}> {
    try {
      const url = environment.clientInfoApiUrl + '/clientInfoes/filterE2EClients';
      const headers = new HttpHeaders({
        'appName': 'E2E',
        'transactionId': Date.now().toString()
      });
      if(!clientUniqueIds?.size) {
        return of({data: []});
      }
      if(this.clientInfoesCallCache) {
        return this.clientInfoesCallCache;
      }
      this.clientInfoesCallCache = this.httpClient.post<ClientInfoModel[] & {exception: string}>(url, Array.from(clientUniqueIds), { headers }).pipe(shareReplay(1), map(
        e2eClients=>{
        if(e2eClients.exception) {
          return {data: []}
        }
        return {  data: (e2eClients || []).map((e2eClient: ClientInfoModel) => ({
            clientCode: e2eClient.clientCode,
            clientName: e2eClient.clientName,
            clientUniqueId: String(e2eClient.clientIdentifier),
            rdcLevel: e2eClient.rdc
        } as any))}
      }));
      return this.clientInfoesCallCache
    } catch (error) {
      return of({data: []});
    }
  }

  setClient(selectedClient: ClientModel): void {
    this._selectedClient.next(selectedClient);
  }

  setHasMultiClient(multiClient: boolean){
    this._hasMultiClient.next(multiClient);
  }

}
