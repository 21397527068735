import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {map, Observable, of, switchMap} from "rxjs";
import {AuthorizationService} from "angular-component-library";
import {ClientService} from "../services/client/client.service";
import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root',
})
export class InvalidClientGuard  {
  constructor(
    private authorizationService: AuthorizationService,
    private clientService: ClientService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const clientUniqueId = route.paramMap.get('clientUniqueId');
    return this.authorizationService.getAuthorizedUser().pipe(switchMap(userInfo=> {
      if(!userInfo) {
        return of(null);
      }
      return this.clientService.getUserClients()
    }), map(responseClients=> {
      if(!responseClients) {
        return false;
      }

      const assignedClients = responseClients.data
      const selectedClient = assignedClients.find(client=> client.clientUniqueId === clientUniqueId);
      return !!selectedClient;
    }));
  }
}
