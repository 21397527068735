import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {AuthorizationService} from "angular-component-library";
import {ClientService} from "../services/client/client.service";
import {catchError, forkJoin, of} from "rxjs";
import {environment} from "../../environments/environment";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Injectable} from "@angular/core";

@Injectable({
    providedIn: 'root',
})
export class ViewDocumentGuard {
    constructor(
        private authorizationService: AuthorizationService,
        private clientService: ClientService,
        private httpClient: HttpClient
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        const clientUniqueId = route.paramMap.get('clientUniqueId');

        return new Promise((resolve, reject) => {
            this.authorizationService.getAuthorizedUser().subscribe(userInfo => {
                this.clientService.getUserClients().subscribe(responseClients => {
                    if(!responseClients) {
                        resolve(false);
                    }
                    const assignedClients = responseClients.data;

                    const headers = new HttpHeaders({
                        'appName': 'E2E',
                        'transactionId': Date.now().toString()
                    });
                    const clientHierarchyRequests = [];
                  assignedClients.forEach(client => {
                    clientHierarchyRequests.push(this.httpClient.get(
                      environment.cohRestApiUrl + `/v1/getClientOrgHierarchyByClientIdentifier?clientIdentifier=${client.clientUniqueId}`, {headers})
                      .pipe(catchError(error => {
                        return of(null)
                      }))
                    )
                  });

                    const cuidsFromAssignedClientHierarchies = new Set();
                    try {
                        forkJoin(clientHierarchyRequests).subscribe(assignedClientsHierarchies => {
                            assignedClientsHierarchies.filter(clientHierarchy => !!clientHierarchy).forEach(clientHierarchy => {
                                cuidsFromAssignedClientHierarchies.add(clientHierarchy['clientOrg']['clientIdentifier'].toString());
                                clientHierarchy['clientOrg']['children'].forEach(child => {
                                    cuidsFromAssignedClientHierarchies.add(child['clientIdentifier'].toString());
                                })
                            })
                            resolve(cuidsFromAssignedClientHierarchies.has(clientUniqueId));
                        })
                    } catch (error) {
                        resolve(false);
                    }
                });
            });
        });
    }
}
