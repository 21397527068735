import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {MetricConfigModel, ProgressTracker} from '@pbm-shared/pbm-shared-models';
import {forkJoin, map, Observable, of, shareReplay, Subject, takeUntil, tap} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MetricService {
  progressTrackerUrl: string;
  metricsUrl: string;
  private clientGoLiveData: {[key: string]: any} = {};
  private clientProgressTracker: {[key: string]: any} = {};
  private clientMetricsConfig: {[key: string]: MetricConfigModel[]} = {};
  destroy$ = new Subject<boolean>();
  metricConfigRequest$: Observable<MetricConfigModel[]>;
  metricConfigTypes = ['CALL', 'CLAIM', 'MEMBER_DATA'];

  constructor(
    private http: HttpClient,
    @Inject('ENVIRONMENT') public env: any
  ) {
    this.progressTrackerUrl = env.progressTrackerApiUrl;
    this.metricsUrl = env.metricApiUrl;
  }

  public getGoLiveDate(clientID: string) {
    if(this.clientGoLiveData[clientID]) {
      return of(this.clientGoLiveData[clientID]);
    }
    return this.http.get<any>(`${this.progressTrackerUrl}/golivedate/fetch?clientId=${clientID}`).pipe(tap(value=> {
      this.clientGoLiveData[clientID] = value;
    }))
  }

  public getProgressTracker(clientID: string) {
    if(this.clientProgressTracker[clientID]) {
      return of(this.clientProgressTracker[clientID]);
    }
    return this.http.get<ProgressTracker>(`${this.progressTrackerUrl}/progresstracker/fetch?clientId=${clientID}`).pipe(tap(value=> {
      this.clientProgressTracker[clientID] = value;
    }))
  }

  public getMetricsConfig(clientCode: string) {
    if(this.clientMetricsConfig[clientCode]) {
      return of(this.clientMetricsConfig[clientCode]);
    }

    if(!this.metricConfigRequest$) {
      this.metricConfigRequest$ = this.http.get<MetricConfigModel[]>(`${this.metricsUrl}/v1/metrics/config/${clientCode}`).pipe(tap(value=> {
        this.clientMetricsConfig[clientCode] = value;
      })).pipe(shareReplay(1)).pipe(takeUntil(this.destroy$));
    }

    return this.metricConfigRequest$;
  }

  public isLiveDateGreaterThanCurrent(liveDateResponse: any) {
    const currentDate = new Date().toISOString().split('T')[0];
    return liveDateResponse.goliveDate && liveDateResponse.goliveDate > currentDate
  }
  public getProgressAndGoLive(clientId: string) {
    let goLiveData;
    return forkJoin([this.getGoLiveDate(clientId), this.getProgressTracker(clientId)]).pipe(map( ([liveDateResponse, progressResponse]) => {
      goLiveData = liveDateResponse
      if(this.isLiveDateGreaterThanCurrent(liveDateResponse)) {
        return progressResponse;
      }
      return null;
    } ), map(traker=> {
      return [goLiveData, traker]
    }));
  }

  public showReportingTab(clientCode: string) {
    if(this.clientMetricsConfig[clientCode]?.length < this.metricConfigTypes.length) {
      return true;
    }

    let showReportingTab = false;
    this.metricConfigTypes.forEach(type => {
      if(this.clientMetricsConfig[clientCode]?.some(config => config.metricsType == type && !config.disable)) {
        showReportingTab = true;
      }
    });
    return showReportingTab;
  }

  public clearMetricConfigObservable() {
    this.destroy$.next(true);
    this.metricConfigRequest$ = undefined;
  }
}
