import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from "./guards/auth.guard";
import { ShellContextResolver } from './shell-context.resolver';
import { StubComponent } from './stub/stub.component';
import { ProgressContextResolver } from './progress-context.resolver';
import { AccessibilityComponent } from './legal-pages/accessibility/accessibility.component';
import { TermsOfUseComponent } from './legal-pages/terms-of-use/terms-of-use.component';
import { PrivacyPolicyComponent } from './legal-pages/privacy-policy/privacy-policy.component';
import { getPageTitle, PageTitleRoutesEnum } from './shared/getPageTitle';
import {ViewDocumentGuard} from "./guards/view-document.guard";
import {InvalidClientGuard} from "./guards/invalid-client.guard";
import {MetricContextResolver} from "./metric-context.resolver";


const routes: Routes = [
  {
    path: 'accessibility',
    component: AccessibilityComponent,
    title: getPageTitle(PageTitleRoutesEnum.Accessibility)
  },
  {
    path: 'privacy_policy',
    component: PrivacyPolicyComponent,
    title: getPageTitle(PageTitleRoutesEnum.PrivacyPolicy)
  },
  {
    path: 'terms_of_use',
    component: TermsOfUseComponent,
    title: getPageTitle(PageTitleRoutesEnum.TermsOfUse)
  },
  {
    path: ':clientUniqueId',
    children: [
      {
        path: 'dashboard',
        canActivate: [AuthGuard, InvalidClientGuard],
        resolve: {
          shellContext: ShellContextResolver,
          progressContext: ProgressContextResolver,
          metricContext: MetricContextResolver
        },
        runGuardsAndResolvers: 'always',
        loadChildren: () => import('@dashboard-mfe/dashboard-mfe-library').then((m) => m.DashboardMfeLibraryModule),
        title: getPageTitle(PageTitleRoutesEnum.Dashboard),
      },
      // COMPANY INFO
      {
        path: 'clientinfo',
        canActivate: [AuthGuard, InvalidClientGuard],
        resolve: {
          shellContext: ShellContextResolver,
        },
        loadChildren: () => import('@caa-ci-oci/client-profile').then((m) => m.ClientProfileModule),
        title: getPageTitle(PageTitleRoutesEnum.ClientProfile),
      },
      {
        path: 'contacts',
        canActivate: [AuthGuard, InvalidClientGuard],
        resolve: {
          shellContext: ShellContextResolver,
        },
        loadChildren: () => import('@client-onboarding/contacts').then((m) => m.ContactsModule),
        title: getPageTitle(PageTitleRoutesEnum.Contacts),
      },
      {
        path: 'clienthierarchy',
        canActivate: [AuthGuard, InvalidClientGuard],
        resolve: {
          shellContext: ShellContextResolver,
        },
        loadChildren: () => import('@client-onboarding/clienthierarchy').then(m => m.ClientHierarchyModule),
        title: getPageTitle(PageTitleRoutesEnum.ClientHierarchy),
      },
      {
        path: 'member-data-transition',
        canActivate: [AuthGuard, InvalidClientGuard],
        resolve: {
          shellContext: ShellContextResolver,
        },
        loadChildren: () => import('@client-onboarding/member-data-transition').then(m => m.MemberDataTransitionModule),
        title: getPageTitle(PageTitleRoutesEnum.MemberDataTransition),

      },
      {
        path: 'brandcommunications',
        canActivate: [AuthGuard, InvalidClientGuard],
        resolve: {
          shellContext: ShellContextResolver,
        },
        component: StubComponent,
        // loadChildren: () => import('@client-onboarding/brandcommunications').then(m => m.BrandCommunicationsModule),
        title: getPageTitle(PageTitleRoutesEnum.BrandCommunications),
      },
      {
        path: 'clientcontacts',
        canActivate: [AuthGuard, InvalidClientGuard],
        resolve: {
          shellContext: ShellContextResolver,
        },
        loadChildren: () => import('@client-onboarding/client-contacts-information').then((m) => m.ClientContactsInformationModule),
        data: { contactType: 'CLIENT' },
        title: getPageTitle(PageTitleRoutesEnum.Clientcontacts)
      },
      {
        path: 'cvscontacts',
        canActivate: [AuthGuard, InvalidClientGuard],
        resolve: {
          shellContext: ShellContextResolver,
        },
        loadChildren: () => import('@client-onboarding/client-contacts-information').then((m) => m.ClientContactsInformationModule),
        data: { contactType: 'CVS' },
        title: getPageTitle(PageTitleRoutesEnum.Cvscontacts)
      },
      {
        path: 'vendorcontacts',
        canActivate: [AuthGuard, InvalidClientGuard],
        resolve: {
          shellContext: ShellContextResolver,
        },
        loadChildren: () => import('@client-onboarding/client-contacts-information').then((m) => m.ClientContactsInformationModule),
        data: { contactType: 'VENDOR' },
        title: getPageTitle(PageTitleRoutesEnum.Vendorcontacts),
      },
      {
        path: 'consultantcontacts',
        canActivate: [AuthGuard, InvalidClientGuard],
        resolve: {
          shellContext: ShellContextResolver,
        },
        loadChildren: () => import('@client-onboarding/client-contacts-information').then((m) => m.ClientContactsInformationModule),
        data: { contactType: 'CONSULTANT' },
        title: getPageTitle(PageTitleRoutesEnum.Consultantcontacts),
      },
      // {
      //   path: 'overrides',
      //   canActivate: [AuthGuard, InvalidClientGuard],
      //   resolve: {
      //     shellContext: ShellContextResolver,
      //   },
      //   loadChildren: () => import('@caa-ovrd-oci/overrides').then((m) => m.OverridesModule)
      // },
      // CLINICAL PROGRAMS - CPM
      {
        path: 'clinical',
        canActivate: [AuthGuard, ViewDocumentGuard],
        resolve: {
          shellContext: ShellContextResolver,
        },
        loadChildren: () => import('@smart-cpm/smart-cpm-mfe-library').then((m) => m.ProgramManagementModule),
        title: getPageTitle(PageTitleRoutesEnum.Clinical)
      },
      {
        path: 'formularysearch',
        canActivate: [AuthGuard, InvalidClientGuard],
        resolve: {
          shellContext: ShellContextResolver,
        },
        title: getPageTitle(PageTitleRoutesEnum.Formularysearch),
        loadChildren: () =>
          import('@formulary-drug-search/formulary-drug-search-mfe-library').then((m) => m.FormularyDrugSearchMfeLibraryModule)
      },
      // BENEFITS - CPR
      {
        path: 'plandashboard',
        canActivate: [AuthGuard, ViewDocumentGuard],
        resolve: {
          shellContext: ShellContextResolver,
        },
        title: getPageTitle(PageTitleRoutesEnum.Plandashboard),
        loadChildren: () => import('@cpr-mfe/cpr-mfe-library').then((m) => m.CprMfeLibraryModule),
      },
      //  commenting out as part of US688734: Hide Member Eligibility tab
      // ELIGIBILITY - MEMBER ELIGIBILITY / EWF
      // {
      //   path: 'eligibility',
      //   canActivate: [AuthGuard, InvalidClientGuard],
      //   resolve: {
      //     shellContext: ShellContextResolver,
      //   },
      //   loadChildren: () => import('@ewf-mfe/ewf-mfe-library').then((m) => m.EwfMfeLibraryModule),
      //   title: getPageTitle(PageTitleRoutesEnum.Eligibility)
      // },
      // REPORTING - METRIC MFE
      {
        path: 'reporting',
        canActivate: [AuthGuard, InvalidClientGuard],
        loadChildren: () => import('@metric-mfe/metric-mfe-library').then((m) => m.MetricMfeLibraryModule
        ),
        title: getPageTitle(PageTitleRoutesEnum.Reporting),
        resolve: {
          shellContext: ShellContextResolver,
          progressContext: ProgressContextResolver,
          metricContext: MetricContextResolver
        }
      },
      {
        path: 'clinicalprogram',
        canActivate: [AuthGuard, InvalidClientGuard],
        loadChildren: () => import('@smart-cpm/smart-cpm-mfe-library').then((m) => m.ProgramManagementModule),
        title: getPageTitle(PageTitleRoutesEnum.Clinicalprogram),
        resolve: {
          shellContext: ShellContextResolver
        }
      },
      {
        path: 'stub',
        component: StubComponent
      },
    ]
  },
  {
    path: '',
    canActivate: [AuthGuard, InvalidClientGuard],
    component: StubComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, onSameUrlNavigation: 'reload' })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
