import {Environment} from "./environment.model";

export const dashboardNavItem = {
  label: 'Dashboard',
  route: 'dashboard'
}

export const companyNavItem = (env: Environment) => {
  return {
    label: 'Client Info',
    children: [
      {
        label: 'Client Profile',
        route: 'clientinfo'
      },
      {
        label: 'Contacts',
        route: 'contacts',
      },
      {
        label: 'Client Hierarchy',
        route: 'clienthierarchy'
      },
      ...env.brandcommunicationsFlag ? [{
        label: 'Brand & Communications Center',
        route: 'brandcommunications'
      }] : [],
      ...env.memberDataTransitionFlag ? [{
        label: 'Member Data Transition',
        route: 'member-data-transition'
      }] : [],
    ],
    moreOptions: true
  }
}

export const clinicalNavItem = {
  label: 'Clinical Plans',
  children: [{
    label: 'Clinical Plan Management',
    route: 'clinical',
  }, {
    label: 'Formulary & Drug Search',
    route: 'formularysearch',
  }],
  moreOptions: true
}


// TODO: planhistory route?
export const benefitsAndPlansNavItem = {
  label: 'Benefit Plans',
  route: 'plandashboard',
  moreOptions: false
}
// hiding Member Eligibility as part of US688734: Hide Member Eligibility tab
// export const eligibilityNavItem = {
//   label: 'Member Eligibility',
//   route: 'eligibility',
//   moreOptions: false
// }

export const reportingNavItem = (showReportingTab: boolean) => {
  return showReportingTab ? [{label: 'Reporting',
    route: 'reporting'}] : []
}

// need to add eligibilityNavItem later once we enable Member Eligibility
export const AccessTypeAndNavigationAccess = (env: Environment, showReportingTab: boolean) => {
  return {
    "CVS Health Colleague": [dashboardNavItem, companyNavItem(env), clinicalNavItem, benefitsAndPlansNavItem, ...reportingNavItem(showReportingTab)],
    "Client User": [dashboardNavItem, companyNavItem(env), clinicalNavItem, benefitsAndPlansNavItem, ...reportingNavItem(showReportingTab)],
    "Consultant User": [dashboardNavItem, companyNavItem(env), clinicalNavItem, benefitsAndPlansNavItem, ...reportingNavItem(showReportingTab)],
  }
}
