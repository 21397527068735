import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule, } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DecimalPipe } from '@angular/common';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';

import { AuthCodePKCEService, AuthenticationService, CVSConfirmationDialogModule, } from 'angular-component-library';
import { SharedModule } from './shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from 'src/environments/environment';
import { EnvironmentService } from './services/environment/environment.service';
import { ShellContextService } from './services/shell-context/shell-context.service';
import { TokenInterceptor } from './auth/token.interceptor';
import { NavigationComponent } from './navigation/navigation.component';
import { BenefitsComponent } from './benefits/benefits.component';
import { FooterComponent } from './footer/footer.component';
import { ClientSelectionModalComponent } from './client-selection-modal/client-selection-modal.component';
import { AccessibilityComponent } from './legal-pages/accessibility/accessibility.component';
import { TermsOfUseComponent } from './legal-pages/terms-of-use/terms-of-use.component';
import { PrivacyPolicyComponent } from './legal-pages/privacy-policy/privacy-policy.component';
import { DashboardMfeLibraryModule } from '@dashboard-mfe/dashboard-mfe-library';

@NgModule({
  declarations: [
    AppComponent,
    NavigationComponent,
    FooterComponent,
    BenefitsComponent,
    ClientSelectionModalComponent,
    AccessibilityComponent,
    TermsOfUseComponent,
    PrivacyPolicyComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    CVSConfirmationDialogModule,
    MatMenuModule,
    DashboardMfeLibraryModule,
  ],
  providers: [
    EnvironmentService,
    ShellContextService,
    DecimalPipe,
    {
      provide: APP_INITIALIZER,
      useFactory: (ds: EnvironmentService) => () => ds.replaceEnvironment(),
      deps: [EnvironmentService],
      multi: true,
    },
    {
      provide: 'ENVIRONMENT',
      useValue: environment
    },
    {
      provide: AuthenticationService,
      useClass: AuthCodePKCEService
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule { }
