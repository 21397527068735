<nav class="app-navigation">
  <div class="app-container-centered">
    <div class="app-nav-list">
      <a href="/#/" class="logo-container">
        <img class="mypbm-logo" src="../../assets/images/mypbm_logo.png" alt="myPBM logo">
      </a>
      <ul>
        <li *ngFor="let page of pages; let i = index">
          <button (click)="trackNavButtonClick(page.label)" *ngIf="page.children?.length" class="paragraph-m-strong"
            [ngClass]="{ active: page.route === currentRoute }" [matMenuTriggerFor]="menu" [matMenuTriggerData]="page"
            (menuClosed)="page.isExpanded = false" (menuOpened)="page.isExpanded = true">{{ page.label }}
            <img src="assets/icons/angle-down-xs.svg" [class]="{ 'drop-down-icon': true, 'arrow-up': page.isExpanded }" alt="" />
          </button>
          <a (click)="trackNavLinkClick(page.route)" *ngIf="!page.children?.length" [routerLink]="selectedClient?.clientUniqueId +'/'+ page.route" class="paragraph-m-strong font-link-heading"
            [ngClass]="{ active: page.route === currentRoute }">{{ page.label }}
          </a>
        </li>
      </ul>
      <div class="profile-icons-section">
        <button
          (click)="onNotificationClick()"
          [ngClass]="{'notification-dot': showNotificationStatus()}" class = "notification-icon-container"
          (menuClosed)="isNotificationExpanded = false" (menuOpened)="isNotificationExpanded = true"
          [matMenuTriggerFor]="menuNotification">
          <mat-icon svgIcon="bell-black" aria-hidden="false" aria-label="Notification" alt="'notification-button"
            [class]="this.isNotificationExpanded ? 'notification-icon notification-icon-active' : 'notification-icon'"></mat-icon>
        </button>
        <button class="profile-icon" (menuClosed)="isUserExpanded = false" (menuOpened)="isUserExpanded = true"
          [matMenuTriggerFor]="menuUser">
          <mat-icon svgIcon="profile-circle" aria-hidden="false" aria-label="Profile" alt="profile-button"
            class="profile-circle-icon" [ngClass]="{ 'profile-active': isUserExpanded }"></mat-icon>
          <mat-icon svgIcon="profile-caret" aria-hidden="true" aria-label="Profile"
            [alt]="isUserExpanded ? 'up-arrow' : 'down-arrow'" class="profile-arrow"
            [ngClass]="{ 'arrow-up profile-active': isUserExpanded }"></mat-icon>
        </button>
      </div>
    </div>
  </div>
  <div class="bottom-header-container">
    <div class="header-black">
      <div class="name-group">
        <mat-icon svgIcon="store-icon" alt="" class="nav-store-icon"></mat-icon>
        <span class="paragraph-s-strong">{{ selectedClient?.clientName }}</span>
        <button
          class="clr-white paragraph-s"
          (click)="onChangeClick()"
          (keydown.space)="onChangeClick()"
        >
          {{change}}
      </button>
      </div>
  </div>
  </div>
  <mat-menu #menu="matMenu" [class]="'menu-content-custom'">
    <ng-template let-items="children" matMenuContent>
      <div *ngFor="let item of items; let i = index" (keydown.tab)="$event.stopPropagation()">
        <button mat-menu-item (click)="routeToLink('/' + selectedClient.clientUniqueId + '/' + item.route, item?.children?.length)">
          <span *ngIf="item?.children?.length" class="font-link-heading">{{ item.label }}</span>
          <ps-link  *ngIf="!item?.children?.length" subtype="inline" class="link-cls-bold">{{ item.label
            }}</ps-link>
        </button>
        <ng-container *ngFor="let child of item?.children || []">
          <button mat-menu-item (click)="routeToLink('/'+ selectedClient.clientUniqueId + '/' + child.route, null)">
            <ps-link subtype="inline">{{ child.label }}</ps-link>
          </button>
        </ng-container>
      </div>
      <ng-container *ngTemplateOutlet="navLinkFooter"></ng-container>
    </ng-template>
  </mat-menu>

  <!-- User Profile Menu -->
  <mat-menu #menuUser="matMenu" [class]="'menu-user-content-custom'">
    <div class="modal-triangle"></div>
    <div class="menu-welcome-container">
      <span class="welcome-text"> Welcome, {{ loggedInUser?.firstName }} </span>
    </div>
    <div class="menu-logout-container">
      <ps-button [isFullWidth]="true" [variant]="'solid'" (click)="authenticationService.logout()">
        Sign Out
      </ps-button>
    </div>
    <ps-divider></ps-divider>
    <div class="opt-in-switch" (click)="onToggleEmails($event)">
      <ps-switch label="Opt in to email notifications" [isChecked]="isEmailOptedIn"></ps-switch>
    </div>
    <ng-container *ngTemplateOutlet="navFooter"></ng-container>
  </mat-menu>

  <!-- Notifications Menu -->
  <mat-menu #menuNotification="matMenu" [class]="'menu-notification-content-custom'">
    <div class="modal-triangle"></div>
    <div class="notifications-container">
      <ng-container *ngIf="
          notifications?.length > 0;
          then notificationTemplate;
          else noNotificationTemplate
        ">
      </ng-container>
    </div>
    <ng-template #notificationTemplate>
      <ul class="notification-list notification-menu-item" mat-menu-item
          (keydown.tab)="$event.stopPropagation()" (keydown.enter)="$event.stopPropagation()"
          (click)="$event.stopPropagation()">
        <li class="notification-container"
            (keydown.shift.tab)="$event.stopPropagation()"
            *ngFor="let notification of notifications; index as i; trackBy: trackByFn">
          <app-notification-item
            (mouseover)="updateNotification(notification.id);"
            (deleteEvent)="deleteNotification($event)"
            [notification]="notification"
          />
          <ps-divider></ps-divider>
        </li>
      </ul>
      <ps-link
        tabindex="0"
        linkId="clear-notifications"
        subtype="default"
        variant="inline"
        class="clear-notifications paragraph-m"
        (click)="clearNotifications($event)"
        (keydown.enter)="clearNotifications($event)">
        Clear all notifications
      </ps-link>
    </ng-template>
    <ng-template #noNotificationTemplate>
      <div class="notification-menu-item" (click)="$event.stopPropagation()" mat-menu-item>
        <div class="notification">
          <div class="paragraph-m-strong">No Notifications</div>
          <div class="notification-message paragraph-m">
            We’ll alert you when something needs attention.
          </div>
        </div>
      </div>
    </ng-template>
    <ng-container *ngTemplateOutlet="navFooter"></ng-container>
  </mat-menu>
</nav>

<app-client-selection-modal [isChange]="true"></app-client-selection-modal>

<ng-template #navFooter>
  <div role="button" class="nav-menu-footer paragraph-m-strong" (click)="onCloseNotifications()">
    Close&nbsp;
    <ps-icon color="neutral" size="medium" icon-label="Birdy" class="notifications-close-icon">
      <svg fill="#000000" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M256,48C141.31,48,48,141.31,48,256s93.31,208,208,208,208-93.31,208-208S370.69,48,256,48Zm75.31,260.69a16,16,0,1,1-22.62,22.62L256,278.63l-52.69,52.68a16,16,0,0,1-22.62-22.62L233.37,256l-52.68-52.69a16,16,0,0,1,22.62-22.62L256,233.37l52.69-52.68a16,16,0,0,1,22.62,22.62L278.63,256Z" />
      </svg>
    </ps-icon>
  </div>
</ng-template>

<ng-template #navLinkFooter>
  <div role="button" class="nav-menu-footer paragraph-m-strong">
    Close&nbsp;
    <ps-icon color="neutral" size="medium" icon-label="Birdy" class="notifications-close-icon">
      <svg fill="#000000" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M256,48C141.31,48,48,141.31,48,256s93.31,208,208,208,208-93.31,208-208S370.69,48,256,48Zm75.31,260.69a16,16,0,1,1-22.62,22.62L256,278.63l-52.69,52.68a16,16,0,0,1-22.62-22.62L233.37,256l-52.68-52.69a16,16,0,0,1,22.62-22.62L256,233.37l52.69-52.68a16,16,0,0,1,22.62,22.62L278.63,256Z" />
      </svg>
    </ps-icon>
  </div>
</ng-template>
